/**
 * Integration environment configuration
 */
import { userLocale } from '../app/models/locale.model';

export const environment = {
  phraseToken:
    '9b677cac475eb92956864df5e1f63ec86b0d19004232e268b3508665bc5535f3',
  locale: userLocale || 'en-GB',
  lambdaEndpoint: 'https://api.unsubscribe-test.porsche.com/',
  env: 'preview'
};
