export enum Locale {
  DE = 'de', // German
  EN = 'en', // English
  ES = 'es', // Spanish
  FR = 'fr', // French
  IT = 'it', // Italian
  JA = 'ja', // Japanese
  KO = 'ko', // Korean
  NL = 'nl', // Dutch
  PL = 'pl', // Polish
  PT = 'pt', // Portuguese
  RU = 'ru', // Russian
  TH = 'th', // Thai
  ZH = 'zh', // Chinese - China
  ZF = 'zf' // Chinese - Taiwan
}

interface MarketArea {
  languages: string[];
  fallback: string;
}

//Mapping of PAG Market Area IDs to possible Region-Language Combinations
export const marketAreaMapping: Record<string, MarketArea> = {
  PCNA: { languages: ['en-US'], fallback: 'en-US' },
  PBR: { languages: ['pt-BR'], fallback: 'pt-BR' },
  PLA: { languages: ['es-AR', 'es-CL', 'es-CO', 'es-DO', 'es-EC', 'es-SV', 'fr-GP', 'es-GT', 'en-JM', 'es-MX', 'es-PA', 'es-PY', 'gu-PY', 'es-PE', 'qu-PE', 'es-PR', 'en-PR', 'en-TT'], fallback: 'es-MX' },
  PCL: { languages: ['en-CA', 'fr-CA'], fallback: 'en-CA' },
  ICL: { languages: ['en-CA', 'fr-CA'], fallback: 'en-CA' },
  ICNA: { languages: ['en-US'], fallback: 'en-US' },
  ILA: { languages: ['es-AR', 'es-CL', 'es-CO', 'es-DO', 'es-EC', 'es-SV', 'fr-GP', 'es-GT', 'en-JM', 'es-MX', 'es-PA', 'es-PY', 'gu-PY', 'es-PE', 'qu-PE', 'es-PR', 'en-PR', 'en-TT'], fallback: 'es-MX' },
  PCEE: { languages: ['hy-AM', 'ru-AM', 'az-AZ', 'ru-AZ', 'be-BY', 'ru-BY', 'bs-BA', 'hr-BA', 'sr-BA', 'bg-BG', 'hr-HR', 'el-CY', 'tr-CY', 'cs-CZ', 'sk-CZ', 'et-EE', 'ka-GE', 'el-GR', 'hu-HU', 'he-IL', 'ar-IL', 'kk-KZ', 'ru-KZ', 'lv-LV', 'lt-LT', 'mt-MT', 'en-MT', 'ro-MD', 'ru-MD', 'mk-MK', 'ro-RO', 'sr-RS', 'sk-SK', 'sl-SI', 'tr-TR', 'uk-UA', 'ru-UA', 'uz-UZ', 'ru-UZ'], fallback: 'en-MT' },
  PCGB: { languages: ['en-GB'], fallback: 'en-GB' },
  PCH: { languages: ['de-CH', 'fr-CH', 'it-CH'], fallback: 'de-CH' },
  PD: { languages: ['de-DE'], fallback: 'de-DE' },
  PIB: { languages: ['es-ES', 'pt-PT'], fallback: 'es-ES' },
  PIT: { languages: ['it-IT'], fallback: 'it-IT' },
  POF: { languages: ['fr-FR'], fallback: 'fr-FR' },
  PNE: { languages: ['nl-NL', 'nl-BE', 'fr-BE', 'de-BE', 'no-NO', 'lb-LU', 'fr-LU', 'de-LU', 'en-NL'], fallback: 'en-NL' },
  ID: { languages: ['de-DE'], fallback: 'de-DE' },
  IBE: { languages: ['nl-BE', 'fr-BE', 'de-BE'], fallback: 'nl-BE' },
  ICEE: { languages: ['hy-AM', 'ru-AM', 'az-AZ', 'ru-AZ', 'be-BY', 'ru-BY', 'bs-BA', 'hr-BA', 'sr-BA', 'bg-BG', 'hr-HR', 'el-CY', 'tr-CY', 'cs-CZ', 'sk-CZ', 'et-EE', 'ka-GE', 'el-GR', 'hu-HU', 'he-IL', 'ar-IL', 'kk-KZ', 'ru-KZ', 'lv-LV', 'lt-LT', 'mt-MT', 'en-MT', 'ro-MD', 'ru-MD', 'mk-MK', 'ro-RO', 'sr-RS', 'sk-SK', 'sl-SI', 'tr-TR', 'uk-UA', 'ru-UA', 'uz-UZ', 'ru-UZ'], fallback: 'en-MT' },
  ICGB: { languages: ['en-GB'], fallback: 'en-GB' },
  ICH: { languages: ['de-CH', 'fr-CH', 'it-CH'], fallback: 'de-CH' },
  IIB: { languages: ['es-ES', 'pt-PT'], fallback: 'es-ES' },
  IIT: { languages: ['it-IT'], fallback: 'it-IT' },
  INO: { languages: ['no-NO'], fallback: 'no-NO' },
  IOF: { languages: ['fr-FR'], fallback: 'fr-FR' },
  IPL: { languages: ['pl-PL'], fallback: 'pl-PL' },
  INL: { languages: ['nl-NL'], fallback: 'nl-NL' },
  ILU: { languages: ['lb-LU', 'fr-LU', 'de-LU'], fallback: 'lb-LU' },
  PNO: { languages: ['no-NO'], fallback: 'no-NO' },
  PIS: { languages: ['de-DE'], fallback: 'de-DE' },
  PPL: { languages: ['pl-PL'], fallback: 'pl-PL' },
  PAP: { languages: ['si-LK', 'ta-LK', 'th-TH', 'vi-VN', 'ms-MY', 'en-MY', 'ms-BN', 'en-BN', 'mn-MN', 'ru-MN', 'tl-PH', 'en-PH', 'id-ID', 'en-ID', 'en-SG', 'zh-SG', 'ms-SG', 'ta-SG', 'km-KH', 'fr-NC', 'fr-PF', 'en-NZ', 'mi-NZ'], fallback: 'en-NZ' },
  PCA: { languages: ['en-AU'], fallback: 'en-AU' },
  PJ: { languages: ['ja-JP'], fallback: 'ja-JP' },
  PKO: { languages: ['ko-KR'], fallback: 'ko-KR' },
  PME: { languages: ['ar-LB', 'fr-LB', 'ar-JO', 'ar-KW', 'ar-BH', 'en-GH', 'ar-SA', 'ar-AE', 'en-MU', 'fr-MU', 'fr-RE', 'ar-MA', 'fr-MA', 'ar-TN', 'fr-TN', 'ar-EG', 'en-EG', 'ar-QA', 'hi-IN', 'en-IN', 'ar-OM', 'en-ZA', 'af-ZA', 'zu-ZA', 'xh-ZA', 'en-KE', 'sw-KE'], fallback: 'en-ZA' },
  PTW: { languages: ['zh-TW'], fallback: 'zh-TW' },
  ICA: { languages: ['en-AU'], fallback: 'en-AU' },
  IJ: { languages: ['ja-JP'], fallback: 'ja-JP' },
  IHK: { languages: ['zh-HK'], fallback: 'zh-HK' },
  ITW: { languages: ['zh-TW'], fallback: 'zh-TW' },
  IKO: { languages: ['ko-KR'], fallback: 'ko-KR' },
  IAP: { languages: ['si-LK', 'ta-LK', 'th-TH', 'vi-VN', 'ms-MY', 'en-MY', 'ms-BN', 'en-BN', 'mn-MN', 'ru-MN', 'tl-PH', 'en-PH', 'id-ID', 'en-ID', 'en-SG', 'zh-SG', 'ms-SG', 'ta-SG', 'km-KH', 'fr-NC', 'fr-PF', 'en-NZ', 'mi-NZ'], fallback: 'en-NZ' },
  PGCN: { languages: ['zh-CN'], fallback: 'zh-CN' },
};

export function getLanguageRegion( language?: string ): string {
  const marketAreaId = new URLSearchParams(new URL(window.location.href).search).get('Importer');
  const marketArea = marketAreaId ? marketAreaMapping[marketAreaId] : null;

  if (language && marketArea) {
    const matchedLanguage = marketArea.languages.find(lang => lang.startsWith(language));
    if (matchedLanguage) {
      return matchedLanguage;
    }else if(mapLanguageToLocale(language).region){
      return language;
    }
  }

  if(marketArea && marketArea.fallback){
    return marketArea.fallback;
  }

  if (language && defaultRegions[language]) {
    return defaultRegions[language];
  }

  return 'en-US';
}

//Standard Region per Language for Fallback
export const defaultRegions: Record<string, string> = {
  af: "af-ZA", sq: "sq-AL", am: "am-ET", ar: "ar-SA", hy: "hy-AM",
  az: "az-AZ", eu: "eu-ES", be: "be-BY", bn: "bn-BD", bs: "bs-BA",
  bg: "bg-BG", ca: "ca-ES", ceb: "ceb-PH", zh: "zh-CN", co: "co-FR",
  hr: "hr-HR", cs: "cs-CZ", da: "da-DK", nl: "nl-NL", en: "en-US",
  eo: "eo-EO", et: "et-EE", fil: "fil-PH", fi: "fi-FI", fr: "fr-FR",
  fy: "fy-NL", gl: "gl-ES", ka: "ka-GE", de: "de-DE", el: "el-GR",
  gu: "gu-IN", ha: "ha-NG", he: "he-IL", hi: "hi-IN", hu: "hu-HU",
  is: "is-IS", id: "id-ID", ga: "ga-IE", it: "it-IT", ja: "ja-JP",
  jv: "jv-ID", kn: "kn-IN", kk: "kk-KZ", km: "km-KH", ko: "ko-KR",
  ku: "ku-TR", ky: "ky-KG", lo: "lo-LA", lv: "lv-LV", lt: "lt-LT",
  lb: "lb-LU", mk: "mk-MK", mg: "mg-MG", ms: "ms-MY", ml: "ml-IN",
  mt: "mt-MT", mi: "mi-NZ", mr: "mr-IN", mn: "mn-MN", my: "my-MM",
  ne: "ne-NP", nb: "nb-NO", nn: "nn-NO", or: "or-IN", ps: "ps-AF",
  fa: "fa-IR", pl: "pl-PL", pt: "pt-BR", pa: "pa-IN", ro: "ro-RO",
  ru: "ru-RU", gd: "gd-GB", sr: "sr-RS", si: "si-LK", sk: "sk-SK",
  sl: "sl-SI", so: "so-SO", es: "es-ES", su: "su-ID", sw: "sw-KE",
  sv: "sv-SE", ta: "ta-IN", te: "te-IN", th: "th-TH", tr: "tr-TR",
  uk: "uk-UA", ur: "ur-PK", uz: "uz-UZ", vi: "vi-VN", cy: "cy-GB",
  xh: "xh-ZA", yi: "yi-001", yo: "yo-NG", zu: "zu-ZA"
};


export const validLocales = Object.values(Locale);



export const userLocale =
getLanguageRegion(
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language);


interface UserLocale {
  language: string;
  region?: string;
}

export function mapLanguageToLocale(language: string): UserLocale {
  const [lang, region] = language.split('-');

  return {
    language: lang,
    region: region || undefined
  };
}



